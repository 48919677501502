import { Options, Vue }        from "vue-class-component";
import { SessionStorageEnum }  from "@/utils/SessionStorageEnum";
import { customShiftsService } from "@services/custom-shifts.service";

import {
  AppTable,
} from "@components";

import Table from "@components/AppTable/Table";
import {CustomShiftsRoutesEnum} from "@/modules/custom-shifts/router";
import {CustomShift} from "@/model/api/CustomShift";
import {plainToClass} from "class-transformer";
import CustomShiftsFilters    from "./CustomShiftsFilters.vue";
import { CustomShiftFilters } from "./CustomShiftFilters";
import {FilterMatchMode} from "primevue/api";
import moment from "moment";
import {AppShiftStatusEnum, appShiftStatuses} from "@/model/enums/AppShiftStatusEnum";
import {Shift} from "@/model/api/Shift";

@Options({
  components: {
    AppTable,
    CustomShiftsFilters
  },

  beforeRouteEnter(to, from, next) {
    if (from.name && !(from.name as string).startsWith(CustomShiftsRoutesEnum.SHIFTS_PREFIX)) {
      sessionStorage.removeItem(SessionStorageEnum.TABLE_STATE_APP_SHIFTS);
      sessionStorage.removeItem(SessionStorageEnum.APP_SHIFTS_ADVANCED_FILTERS);
    }
    next();
  },
  
  beforeRouteLeave() {
    if (this.service?.cancelPendingRequests) {
      this.service.cancelPendingRequests();
    }
  },
})
export default class CustomShiftsPage extends Vue {
  filters: any = null;

  advFilters: CustomShiftFilters = new CustomShiftFilters();

  get service() {
    return customShiftsService;
  }

  get stateKey() {
    return SessionStorageEnum.TABLE_STATE_APP_SHIFTS
  }

  get statusOptions() {
    const statuses = [...appShiftStatuses];

    return statuses.map(s => ({
      label: this.$t(`app_shift.statuses.${AppShiftStatusEnum[s]}`),
      value: s
    }))
  }

  getAddress(shift: Shift, zone: string){
    let address = ''
    if(shift[zone + '_address']) address += `${shift[zone + '_address']}<br>`
    if(shift[zone + '_city']) address += `${shift[zone + '_city']}, `
    if(shift[zone + '_state']) address += `${shift[zone + '_state'].name} (${shift[zone + '_state'].country_code}), `
    if(shift[zone + '_zip']) address += shift[zone + '_zip']
    return address

  }

  statusBadgeColor(status) {
    if(status === AppShiftStatusEnum.REJECTED){
      return 'warning'
    } else if(status === AppShiftStatusEnum.APPROVED){
      return 'success'
    } else {
      return null
    }
  }

  get newRoute(){
    return {
      name: CustomShiftsRoutesEnum.SHIFTS_DETAIL,
      params: { shiftId: 'new' }
    }
  }

  get table() {
    return this.$refs.dataTable as Table;
  }

  get appShiftStatusEnum() {
    return AppShiftStatusEnum
  }

  private saveAdvancedFilters() {
    sessionStorage.setItem(
        SessionStorageEnum.APP_SHIFTS_ADVANCED_FILTERS,
        JSON.stringify(this.advFilters)
    );
  }

  private restoreAdvancedFilters() {
    const fromStorage = JSON.parse(
        sessionStorage.getItem(SessionStorageEnum.APP_SHIFTS_ADVANCED_FILTERS)
    );

    this.advFilters = fromStorage
        ? plainToClass(CustomShiftFilters, fromStorage)
        : this.advFilters;
  }

  goToDetail(shift: CustomShift) {
    this.$router.push({
      name: CustomShiftsRoutesEnum.SHIFTS_DETAIL,
      params: { shiftId: shift.id }
    })
  }

  onDelete(shift: CustomShift) {
    this.$confirmMessage(this.$t(`app_shift.messages.delete_confirm`))
      .then(r => {
        if (r) {
          this.deleteShift(shift);
        }
      })
  }

  selectedShift: CustomShift = null;
  
  private deleteShift(shift: CustomShift) {
    this.$waitFor(
      async () => {
        await customShiftsService.deleteBySystem(shift);
        this.update()
        this.$successMessage(this.$t(`app_shift.messages.delete_success`))
      },
        this.$t(`shift.delete_message_failed`)
    )
  }

  private initFilter() {
    this.filters = {
      created_at: {
        value: this.advFilters.from,
        matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
      },
      status: {
        value: [...appShiftStatuses],
        matchMode: FilterMatchMode.IN
      },
      code: {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH
      }
    };
  }

  onFiltersChange() {
    const table = (this.$refs.dataTable as Table);
    if (table?.filters) {
      const filters = table.filters;

      if (this.advFilters.from && this.advFilters.to) {
        const from = moment(this.advFilters.from).format("YYYY-MM-DD");
        const to   = moment(this.advFilters.to).format("YYYY-MM-DD");

        filters.created_at.value = [ from, to ];
        filters.created_at.matchMode = FilterMatchMode.BETWEEN;
      } else {
        const from = moment(this.advFilters.from).format("YYYY-MM-DD");

        filters.created_at.value = from;
        filters.created_at.matchMode = FilterMatchMode.GREATER_THAN_OR_EQUAL_TO;
      }
    }

    table.applyFilter();
  }

  onReset() {
    this.advFilters = new CustomShiftFilters();
  }

  update() {
    console.debug("update");
    this.table.applyFilter();
  }

  created() {
    this.initFilter();
  }
}