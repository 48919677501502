import { Type } from "class-transformer";
import moment from "moment";

export class ShiftFilters {
    @Type(() => Date)
    from_to: Date[];

    get from() {
        const from = this.from_to?.[0];
        if (from) {
            from.setHours(0);
            from.setMinutes(0);
            from.setSeconds(0);
            return from;
        }

        return null;
    }

    get to() {
        const to = this.from_to?.[1];
        if (to) {
            to.setHours(23);
            to.setMinutes(59);
            to.setSeconds(59);
            return to;
        }

        return null;
    }


    constructor() {
        const from = moment();

        this.from_to = [from.toDate()];
    }
}