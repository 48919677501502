import { Options, Vue } from "vue-class-component";
import {Prop, Provide, Watch} from "vue-property-decorator";
import { classToPlain, plainToClass } from "class-transformer";

import {
  SendMessageDialog,
  ShiftDeleteDialog,
  W3Autocomplete,
  W3Sidebar,
  ZoneAutocomplete
} from "@components";

import { Shift, ShiftStatusEnum } from "@/model/api/Shift";
import { shiftsService } from "@services/shifts.service";
import { fileDownload } from "@/utils/utils";
import { ShiftsRoutesEnum } from "../../router";
import { customShiftsService } from "@services/custom-shifts.service";
import { AppShiftStatusEnum } from "@/model/enums/AppShiftStatusEnum";
import { whitelistUsersService } from "@services/whitelist_users.service";
import { User } from "@/model/api/User";
import { Moment } from "moment";
import { PaymentTypeEnum } from "@/model/enums/PaymentTypeEnum";

@Options({
  components: {
    SendMessageDialog,
    ShiftDeleteDialog,
    W3Autocomplete,
    W3Sidebar,
    ZoneAutocomplete
  },
  beforeRouteLeave() {
    whitelistUsersService.clearUsers()
  }
})
export default class ShiftPage extends Vue {

  displayMessageDialog: boolean = false;
  displayDeleteDialog: boolean = false;

  message: string = null;
  sendToAll: boolean = false;

  @Prop()
  @Provide({ reactive: true })
  readonly shiftId!: String;

  shift: Shift = new Shift();

  @Provide({ reactive: true })
  get isDeleted() {
    return this.shift.status === ShiftStatusEnum.DISABLED;
  }


  @Provide({ reactive: true })
  get hasTickets() {
    return this.shift?.has_tickets;
  }

  onMessageDialogChange() {
    if(this.$route.query.cloned){
      window.location.href = window.location.origin + window.location.pathname;
    }
  }
  
  resetMessage() {
    this.sendToAll = false;
    this.message = null;
    // this.onMessageDialogChange()
  }

  onDeleted() {
    this.$router.replace({
      name: ShiftsRoutesEnum.SHIFTS_LIST
    });
  }

  get actions() {
    const actions = [
      {
        label: 'Export',
        icon: 'pi pi-file-excel',
        command: () => {
          this.export();
        }
      },
      {
        label: 'Send message',
        icon: 'pi pi-send',
        command: () => {
          this.displayMessageDialog = true;
        }
      }
    ];

    if (!this.isNew) {
      actions.push({
        label: 'Delete',
        icon: 'pi pi-trash',
        command: () => {
          this.displayDeleteDialog = true;
        }
      });
    }

    return actions;
  }

  get tabs() {
    let i = 0;

    return [
      {
        label: this.$t('shift.tabs.data'),
        icon: 'fas fa-road',
        to: { name: ShiftsRoutesEnum.SHIFTS_DETAIL },
        class: this.$route.name === this.tabs?.[i++].to.name ? 'p-highlight' : ''
      },
      {
        label: this.$t('shift.tabs.tiket_list'),
        icon: 'fas fa-ticket-alt',
        to: { name: ShiftsRoutesEnum.SHIFTS_DETAIL_TICKETS },
        class: this.$route.name === this.tabs?.[i++].to.name ? 'p-highlight' : ''
      },
    ];
  }


  get isNew() {
    return this.shiftId === 'new';
  }

  get backRoute() {
    return { name: ShiftsRoutesEnum.SHIFTS_LIST }
  }

  private async export() {
    try {
      const response = await shiftsService.exportExcel();

      const fileName = "//TODO_FILE_NAME";

      fileDownload(
        fileName,
        response,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      )
    } catch (error) {
      this.$errorMessage("Export failed");
    }
  }

  checkExpireValidity() {
    return this.shift.expire_at && this.shift.expire_at.isValid()
  }

  checkEndTimeValidity() {
    return this.shift.end_datetime && this.shift.end_datetime.isValid()
  }

  async addUsersToNewShift(user: User, shift_id: number) {
    await whitelistUsersService.addUser({ shift_id, user })
  }

  mounted(){
    if(this.$route.query.cloned){
      this.displayMessageDialog = true;
    }
  }

  onSave() {
    if (
      this.checkExpireValidity() &&
      this.checkEndTimeValidity()
    ) {
      const expire_at: Moment = this.shift.expire_at.clone()
      const end_datetime: Moment = this.shift.end_datetime.clone()
      if (expire_at.diff(end_datetime) <= 0) {
        this.$errorMessage("Expiration must be after end datetime");
        return
      }
    }

    // clean oppositve fields
    switch (this.shift.payment_type) {
      case PaymentTypeEnum.CYCLE:
        this.shift.estimated_ton = 0;
        break;
      case PaymentTypeEnum.TON:
        this.shift.cycles = 0;
        this.shift.is_ton_requested = false;
        break;
      default:
        this.shift.cycles = 0;
        this.shift.estimated_ton = 0;
        break;
    }

    this.$waitFor(
      async () => {
        if (this.isNew) {
          const plainShift = classToPlain(this.shift);
          const res = await shiftsService.create(plainShift as any)

          if (plainShift.custom_shift_id) {
            await customShiftsService.updatePatch({
              id: plainShift.custom_shift_id,
              status: AppShiftStatusEnum.APPROVED
            } as any)
          }

          this.shift = plainToClass(Shift, res)

          if (!res.expire_at) {
            (this.shift as any).expireAt = null
          }

          const cachedWhitelistUsers = [...await whitelistUsersService.getUsers()]
          cachedWhitelistUsers.map((user: User) => {
            return this.addUsersToNewShift(user, this.shift.id)
          })

          await Promise.all(cachedWhitelistUsers);

          await this.$router.replace({
            name: ShiftsRoutesEnum.SHIFTS_DETAIL,
            params: {
              shiftId: res.id
            }
          });
          if (plainShift.custom_shift_id) {
            this.$successMessage(this.$t("app_shift.messages.approve_success"));
          }
          this.$successMessage("Shift successfully created");

          this.message = "New shift available check Libera App";
          this.sendToAll = true;
          this.displayMessageDialog = true;

        } else {
          const plainShift = classToPlain(this.shift);
          plainShift.expire_at ||= null
          const response = await shiftsService.updatePatch(plainShift as any);
          this.shift = plainToClass(Shift, response);
          this.$successMessage("Shift successfully updated");
        }
      },
      "Saving failed"
    )
  }

  async onDelete() {
    this.displayDeleteDialog = true;
  }

  private async _loadShift() {
    this.$waitFor(
      async () => {
        this.shift = await shiftsService.getById(+this.shiftId);
      }
    )
  }

  private async _loadCustomShift(id: number) {
    this.$waitFor(
      async () => {
        this.shift = await customShiftsService.getShift(id);
        this.shift.custom_shift_id = id
      }
    )
  }

  async created() {
    if (this.isNew) {
      if (this.$route.query.custom_shift_id) {
        await this._loadCustomShift(+this.$route.query.custom_shift_id)
      } else {
        this.shift = new Shift();
      }
    } else {
      await this._loadShift();
    }
  }
}