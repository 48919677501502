import { FilterMatchMode } from "primevue/api";
import { Options, Vue }    from "vue-class-component";
import {Inject, Prop} from "vue-property-decorator";

import { ShiftsRoutesEnum }   from "@/modules/shifts/router";
import { SessionStorageEnum } from "@/utils/SessionStorageEnum";
import { ticketsService }     from "@services/tickets.service";
import { Ticket }             from "@/model/api/Ticket";

import {
  AppTable,
  TicketStatus,
  TicketFinishStatus
} from "@components";

import Table from "@components/AppTable/Table";

import TicketDisputeResolverDialog
from "@/components/TicketDisputeResolverDialog/TicketDisputeResolverDialog.vue";

import {
  activeStatuses,
  finishStatuses,
  inactiveStatuses,
  TicketFinishStatusEnum,
  TicketStatusEnum
} from "@/model/enums/TicketStatusEnum";
import moment from "moment";
import { TicketsRoutesEnum } from "@/modules/tickets/router";
import { fileDownload } from "@/utils/utils";
import {PaymentTypeEnum} from "@/model/enums/PaymentTypeEnum";
import {Shift} from "@/model/api/Shift";

@Options({
  inheritAttrs: false,

  components: {
    AppTable,
    TicketStatus,
    TicketFinishStatus,
    TicketDisputeResolverDialog
  },

  beforeRouteEnter(to, from, next) {
    if (from.name && !(from.name as string).startsWith(ShiftsRoutesEnum.SHIFTS_DETAIL)) {
      sessionStorage.removeItem(SessionStorageEnum.TABLE_STATE_SHIFT_TICKETS);
    }

    const tableState = JSON.parse(sessionStorage.getItem(SessionStorageEnum.TABLE_STATE_SHIFT_TICKETS));
    if (tableState?.filters?.shift_id?.value !== to.params.shiftId) {
      sessionStorage.removeItem(SessionStorageEnum.TABLE_STATE_SHIFT_TICKETS);
    }

    next();
  },

  beforeRouteLeave() {
    sessionStorage.removeItem('ticketsOnlyActive');

    if (this.service?.cancelPendingRequests) {
      this.service.cancelPendingRequests();
    }
  },
})
export default class ShiftTickets extends Vue {
  @Inject()
  readonly shiftId!: string;

  @Prop() readonly shift!: Shift;

  filters: any = null;

  exporting: boolean = false;

  onlyActive: boolean = true;

  selectedTicket: Ticket = null;

  displayResolverDialog: boolean = false;

  get exports(){
    return [
      {
        label: 'Excel',
        command: () => {
          this.onExport('excel')
        }
      },
      {
        label: 'PDF',
        command: () => {
          this.onExport('pdf')
        }
      }
    ]
  }

  get canSeeTotalTon() {
    return this.shift.payment_type === PaymentTypeEnum.TON
  }

  get service() {
    return ticketsService;
  }

  get stateKey() {
    return SessionStorageEnum.TABLE_STATE_SHIFT_TICKETS
  }

  get selectedTicketId() {
    return this.selectedTicket?.id;
  }

  get statusOptions() {
    const statuses = [...activeStatuses];

    if (!this.onlyActive) {
      statuses.push(...inactiveStatuses);
    }

    return statuses.map(s => ({
      label: this.$t(`ticket.statuses.${TicketStatusEnum[s]}`),
      value: s
    }))
  }

  get finishStatusOptions() {
    return finishStatuses.map(s => ({
      label: this.$t(`ticket.finish_status.${TicketFinishStatusEnum[s]}`),
      value: s
    }))
  }
  
  getDuration(ticket: Ticket) {
    const startMoment = moment(ticket.datetime_assigned);
    let d = null;

    if (!ticket.datetime_end.isValid()) {
      d = moment.duration(moment().diff(startMoment));
    } else {
      const endMoment = moment(ticket.datetime_end);
      d = moment.duration(endMoment.diff(startMoment));
    }

    const h = Math.floor(d.asHours());
    const m = Math.floor(d.minutes());
    const s = Math.floor(d.seconds());
    
    return [h,m,s].map(x => x.toString().padStart(2, '0')).join(':');
  }

  canDelete(t: Ticket) {
    return t.status === TicketStatusEnum.ACCEPTED_BY_USER;
  }

  isDispute(t: Ticket) {
    return t?.status === TicketStatusEnum.DISPUTE;
  }

  async onExport(type: string) {
    try {
      const filters = (this.$refs.table as Table).filters;

      this.exporting = true

      const response = await ticketsService.exportFile(filters, type);

      this.exporting = false

      const fileName = `TicketList_Export_${moment().format('YYYY_MM_DD')}`;

      fileDownload(
          fileName,
          response,
          type === 'excel' ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" : "application/pdf"
      )
    } catch (error) {
      console.error(error);
      this.exporting = false
      this.$errorMessage("Export failed");
    }
  }
  
  onResolve(t: Ticket) {
    this.selectedTicket = t;
    this.displayResolverDialog = true;
  }

  onResolved() {
    this.displayResolverDialog = false;
    this.selectedTicket = null;

    this.$successMessage("Ticket dispute resolved");
  }

  onResolveCancel() {
    this.displayResolverDialog = false;
    this.selectedTicket = null;
  }

  onUpdateOnlyActive(val: boolean) {
    
    sessionStorage.setItem('ticketsOnlyActive', val.toString());

    const table = (this.$refs.table as Table);

    table.filters.status = {
      value: val ? activeStatuses : null,
      matchMode: FilterMatchMode.IN
    }

    table.onApplyFilter();
  }

  onEdit(ticket: Ticket) {
    this.$router.push({
      name: TicketsRoutesEnum.TICKETS_DETAIL,
      params: {
        ticketId: ticket.id
      }
    });
  }

  onDeleted() {
    this.selectedTicket = null;
    this.refreshTable();
    this.$successMessage("Ticket deleted successfully");
  }
  
  update() {
    this.refreshTable();
  }

  onDelete(ticket: Ticket) {
    this.$confirmMessage("Are you sure to delete this Ticket?")
      .then(r => {
        if (r) {
          this.deleteTicket(ticket);
        }
      })
  }

  private deleteTicket(ticket: Ticket) {
    this.$waitFor(
      async () => {
        await ticketsService.deleteBySystem(ticket);
        this.refreshTable();
        this.$successMessage("Ticket successfully deleted")
      },
      "Deleting Ticket failed"
    )
  }

  private refreshTable() {
    const table = (this.$refs.table as Table);
    table.applyFilter();
  }

  private initFilter() {
    this.filters = {
      shift_id: {
        value: this.shiftId,
        matchMode: FilterMatchMode.EQUALS
      },
      status: {
        value: [...activeStatuses],
        matchMode: FilterMatchMode.IN
      },
      finish_status: {
        value: null,
        matchMode: FilterMatchMode.IN
      },
      datetime_assigned: {
        value: null,
        matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
      },
      datetime_end: {
        value: null,
        matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
      }
    };
  }

  created() {
    const fromStorage = sessionStorage.getItem('ticketsOnlyActive')
    
    if (fromStorage) {
      this.onlyActive = JSON.parse(
        sessionStorage.getItem('ticketsOnlyActive')
      );
    }

    this.initFilter();
  }
}