import * as api                   from "@what3words/api";
import { Options, Vue }           from "vue-class-component";
import {Inject, Prop, Watch} from "vue-property-decorator";
import { Shift } from "@/model/api/Shift";
import { PaymentTypeEnum }        from "@/model/enums/PaymentTypeEnum";
import { ZoneTypeEnum }           from "@/model/enums/ZoneTypeEnum";
import { calcDistance }           from "@/utils/utils";

import {
  TimeZoneDropdown,
  W3Sidebar,
  ZoneAutocomplete,
  ShiftDataAutocomplete,
  ContractAutocomplete,
  MaterialSelect,
  WhitelistUserDialog,
  CompanyAutocomplete
} from "@components";
import {Contract} from "@/model/api/Contract";
import moment from "moment";
import {User} from "@/model/api/User";
import {whitelistUsersService} from "@services/whitelist_users.service";
import {UsersRoutesEnum} from "@/modules/users/router";
import { plainToClass } from "class-transformer";
import { Company } from "@/model/api/Company";
import {State} from "@/model/api/State";

@Options({
  inheritAttrs: false,
  emits: ['save', 'cloned'],
  components: {
    TimeZoneDropdown,
    ShiftDataAutocomplete,
    W3Sidebar,
    WhitelistUserDialog,
    MaterialSelect,
    ZoneAutocomplete,
    ContractAutocomplete,
    CompanyAutocomplete
  }
})
export default class ShiftData extends Vue {
  @Prop() shift!: Shift;

  @Watch('shift')
  onShiftChange() {
    if ((this.shift.id || this.shift.custom_shift_id) && this.shift.is_dump_same_load_site) {
      (this.$refs.autocomplete as any).$el.children[0].disabled = true
    }
  }

  @Prop() readonly isLoading!: boolean;

  @Inject()
  readonly hasTickets!: boolean;
  @Inject()
  readonly isDeleted!: boolean;

  initialValue = null;
  hours: any[] = []

  whitelistUsers: User[] = null;

  displayWhiteListDialog: boolean = false;
  inEdit: 'dump' | 'load'   = null;
  displayW3Sidebar: boolean = false;
  private activeTicketFields = {
    shift_day: null,
    start_datetime: null,
    end_datetime: null,
    expire_at: null,
    pause_minutes: null,
    zone_name: null,
  }

  get dateFormat() {
    return this.$config.dateFormat;
  }

  get zoneTypeLoad() {
    return ZoneTypeEnum.LOAD;
  }

  get zoneTypeDump() {
    return ZoneTypeEnum.DUMP;
  }
  
  get paymentHOUR() {
    return PaymentTypeEnum.HOUR;
  }
  
  get paymentCYCLE() {
    return PaymentTypeEnum.CYCLE;
  }

  get paymentTON() {
    return PaymentTypeEnum.TON;
  }

  editW3Load() {
    this.initialValue = this.shift.w3load;

    this.inEdit = 'load';
    this.displayW3Sidebar = true;
  }

  @Watch('displayWhiteListDialog')
  async onWhitelistModalToggle(opened) {
    if(!opened){
      this.whitelistUsers = [...await whitelistUsersService.getUsers()]
    }
  }

  editW3Dump() {
    this.initialValue = this.shift.w3dump;

    this.inEdit = 'dump';
    this.displayW3Sidebar = true; 
  }

  showDetails(user: User){
    const routeData = this.$router.resolve({
      name: UsersRoutesEnum.USER_DETAIL, params: { userId: user.id }
    });
    window.open(routeData.href, '_blank');
  }

  onPrivateContractSelect(contract: Contract) {
    if(contract){
      this.shift.private_contract_id = contract.id;
    } else {
      this.shift.private_contract = null;
      this.shift.private_contract_id = null;
    }
  }

  onContractSelect(contract: Contract) {
    if(contract){
      this.shift.contract_id = contract.id;
    } else {
      this.shift.contract = null;
      this.shift.contract_id = null;
    }
  }

  onSelectedName($event: any) {
    this.shift.poc_phone = $event.poc_phone
    this.shift.poc_email = $event.poc_email
  }

  async removeUserFromWhitelist(user){
    this.$waitFor(
        async () => {
          await whitelistUsersService.removeUser({
            shift_id: this.shift.id,
            user_id: user.id
          });
          this.$successMessage("User successfully removed from whitelist")
          this.whitelistUsers =  [...await whitelistUsersService.getUsers()]
        },
        this.$t('Operation failed')
    );
  }

  onSelectedJob(shiftSel: Shift) {
    const shift = plainToClass(Shift, shiftSel);

    for (const key in shift) {
      switch (key) {
        default:
          if(key !== 'id'){
            if(!this.hasTickets || !this.activeTicketFields.hasOwnProperty(key)){
              this.shift[key] = shift[key]
            }
          }
      }
    }
  }

  private loadData() {
    const shiftId = this.$route.params.shiftId
    if(shiftId !== 'new'){
      return this.$waitFor(
          async () => {
            whitelistUsersService.getUsers({shift_id: this.$route.params.shiftId}).then((res) => {
              this.whitelistUsers = [...res]
            });
          }
      )
    } else {
      this.whitelistUsers = []
    }
  }


  onSameZoneChange() {
    if(this.shift.is_dump_same_load_site){
      this.shift.zone_dump = this.shift.zone_load
      this.shift.zone_dump_id = this.shift.zone_load_id
      this.shift.w3dump = this.shift.w3load;
      (this.$refs.autocomplete as any).$el.children[0].disabled = true
      this.shift.distance = 0
    } else {
      this.shift.zone_dump = null
      this.shift.zone_dump_id = null
      this.shift.w3dump = null;
      (this.$refs.autocomplete as any).$el.children[0].disabled = false
    }
  }


  private async onRemove(user: User){
    this.$waitFor(
        async () => {
          await whitelistUsersService.removeUser({
            shift_id: this.shift.id,
            user_id: user.id
          });
          this.$successMessage("User successfully removed from whitelist")
        },
        this.$t('Operation failed')
    );
  }

  private async onAdd(user: User){
    this.$waitFor(
        async () => {
          await whitelistUsersService.addUser({
            shift_id: this.shift.id,
            user
          })
          this.$successMessage("User successfully added to whitelist")
        },
        this.$t('Operation failed')
    );
  }

  mounted(){
    for (let i = 1; i < new Array(24).length; i++) {
      let value
      if (i < 10) {
        value = `0${i}:00`
      } else {
        value = `${i}:00`
      }
      this.hours.push(value)
    }
    this.loadData()
  }

  onZoneLoadSelect() {
    this.shift.zone_load_id = this.shift.zone_load?.id;
    this.shift.w3load       = this.shift.zone_load.w3;
    this.shift.load_lat     = this.shift.zone_load.lat;
    this.shift.load_lng     = this.shift.zone_load.lng;
    if(this.shift.is_dump_same_load_site){
      this.shift.zone_dump_id = this.shift.zone_load?.id;
      this.shift.w3dump       = this.shift.zone_load.w3;
      this.shift.dump_lat     = this.shift.zone_load.lat;
      this.shift.dump_lng     = this.shift.zone_load.lng;
    }
    this._calcDistance();
  }

  onZoneDumpSelect() {
    this.shift.zone_dump_id = this.shift.zone_dump?.id;
    this.shift.w3dump       = this.shift.zone_dump.w3;
    this.shift.dump_lat     = this.shift.zone_dump.lat;
    this.shift.dump_lng     = this.shift.zone_dump.lng;

    this._calcDistance();
  }

  onCustomerSelected(company?: Company) {
    if(company) {
      this.shift.customer_company_id = company.id
    } else {
      this.shift.customer_company = null
      this.shift.customer_company_id = null
    }
  }

  changeShiftCode($event){
    if($event.target.value === '' && !!this.shift.job_site){
      this.shift.code = `${this.shift.job_site} 001`
    }
  }

  onSupplierSelected(company?: Company) {
    if(company) {
      this.shift.supplier_company_id = company.id
    } else {
      this.shift.supplier_company = null
      this.shift.supplier_company_id = null
    }
  }

  onSaveW3(w3Result: api.LocationJsonResponse) {
    if ( this.inEdit === 'load' ) {
      this.shift.w3load = w3Result.words;
      this.shift.load_lat = w3Result.coordinates.lat;
      this.shift.load_lng = w3Result.coordinates.lng;
      if(this.shift.is_dump_same_load_site){
        this.inEdit = 'dump'
        this.onSaveW3(w3Result)
      }
    } else {
      this.shift.w3dump = w3Result.words;
      this.shift.dump_lat = w3Result.coordinates.lat;
      this.shift.dump_lng = w3Result.coordinates.lng;
    }
  }

  private _calcDistance() {
    if(this.shift.zone_dump && this.shift.zone_load){
      const x = {
        lat: this.shift.zone_load.lat,
        lng: this.shift.zone_load.lng,
      }

      const y = {
        lat: this.shift.zone_dump.lat,
        lng: this.shift.zone_dump.lng,
      }

      this.shift.distance = calcDistance(x, y);
    }

    return;
  }
}