import { Options, Vue }        from "vue-class-component";
import { FilterMatchMode }     from "primevue/api";
import { ShiftsRoutesEnum }    from "../../router";
import { SessionStorageEnum }  from "@/utils/SessionStorageEnum";

import { shiftsService } from "@services/shifts.service";
import { Shift, ShiftStatusEnum }         from "@/model/api/Shift";

import {  
  AppTable,
  DuplicateShiftDialog,
  SendMessageDialog,
  ShiftDeleteDialog
} from "@components";

import ShiftsFilters    from "./ShiftsFilters.vue";
import { ShiftFilters } from "./ShiftFilters";
import Table            from "@components/AppTable/Table";
import moment from "moment";

@Options({
  components: {
    AppTable,
    DuplicateShiftDialog,
    SendMessageDialog,
    ShiftDeleteDialog,
    ShiftsFilters,
  },

  beforeRouteEnter(to, from, next) {
    if (from.name && !(from.name as string).startsWith(ShiftsRoutesEnum.SHIFTS_PREFIX)) {
      sessionStorage.removeItem(SessionStorageEnum.TABLE_STATE_SHIFTS);
      sessionStorage.removeItem(SessionStorageEnum.SHIFTS_ADVANCED_FILTERS);
    }

    next();
  },
  
  beforeRouteLeave() {
    if (this.service?.cancelPendingRequests) {
      this.service.cancelPendingRequests();
    }
  },
})
export default class ShiftsPage extends Vue {
  filters: any = null;

  get service() {
    return shiftsService;
  }

  get stateKey() {
    return SessionStorageEnum.TABLE_STATE_SHIFTS
  }

  get newRoute(){
    return {
      name: ShiftsRoutesEnum.SHIFTS_DETAIL,
      params: { shiftId: 'new' }
    }
  }

  get table() {
    return this.$refs.dataTable as Table;
  }

  getRowClass({ trucks_engaged, trucks_min, trucks_required, status }: Shift) {
    if(status === ShiftStatusEnum.DISABLED) {
      return "shift_disabled";
    }
    
    if (trucks_engaged >= trucks_required) {
      return "truck_required";
    } else if ( 
      trucks_min > 0 &&
      trucks_engaged <= trucks_required && 
      trucks_engaged >= trucks_min 
    ) {
      return "truck_min";
    }

    return null; 
  }

  goToDetail(shift: Shift) {
    this.$router.push({
      name: ShiftsRoutesEnum.SHIFTS_DETAIL,
      params: { shiftId: shift.id }
    })
  }

  displayDeleteDialog: boolean = false;
  shiftToDelete      : Shift   = null;

  onDelete(shift: Shift) {
    this.shiftToDelete       = shift;
    this.displayDeleteDialog = true;
  }

  showDuplicateDialog: boolean = false; 
  selectedShift: Shift = null; 
  onClone(shift: Shift) {
    this.selectedShift = shift; 

    this.showDuplicateDialog = true; 
  }

  onDuplicateCancel() {
    this.showDuplicateDialog = false;
  }

  displayMessageDialog: boolean = false;
  msgShiftId: number = null; 

  private sendMessageFor(shiftId: number) {
    this.msgShiftId = shiftId;
    this.displayMessageDialog = true;
  }

  async onDuplicateConfirm(clone: Shift) {
    try {
      const cloned = await shiftsService.create(clone);

      // this.sendMessageFor(cloned.id);

      await this.$successMessage(this.$t('shift.duplicated_message'));

      // this.table.applyFilter();
      
      this.showDuplicateDialog = false;

      await this.$router.push({
        name: ShiftsRoutesEnum.SHIFTS_DETAIL,
        params: { shiftId: cloned.id }
      })

    } catch (error) {
      this.$errorMessage(this.$t('Operation failed'))
    }
    
  }
  
  private deleteShift(shift: Shift) {
    this.$waitFor(
      async () => {
        await shiftsService.deleteBySystem(shift);
        this.$successMessage("Shift successfully deleted")
      },
      "Deleting Shift failed"
    )
  }
  
  advFilters: ShiftFilters = new ShiftFilters();

  private initFilter() {
    this.filters = {
      // global: { 
      //   value: null, 
      //   matchMode: FilterMatchMode.CONTAINS 
      // },
      shift_day: {
        value: this.advFilters.from, 
        matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
      },
      code: {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH
      },
      job_site: {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH
      },
      'customer_company.name': {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },
      'supplier_company.name': {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      }
    };
  }

  onReset() {
    this.advFilters = new ShiftFilters();
  }

  onFiltersChange() {
    const table = (this.$refs.dataTable as Table);

    if (table?.filters) {
      const filters = table.filters;

      if (this.advFilters.from && this.advFilters.to) {
        const from = moment(this.advFilters.from).format("YYYY-MM-DD");
        const to   = moment(this.advFilters.to).format("YYYY-MM-DD");
  
        filters.shift_day.value = [ from, to ];
        filters.shift_day.matchMode = FilterMatchMode.BETWEEN;
      } else {
        const from = moment(this.advFilters.from).format("YYYY-MM-DD");
  
        filters.shift_day.value = from;
        filters.shift_day.matchMode = FilterMatchMode.GREATER_THAN_OR_EQUAL_TO;
      }
    }
    
    table.applyFilter(); 
  }

  update() {
    console.debug("update");
    this.table.applyFilter();
  }

  created() {
    this.initFilter();
  }
}